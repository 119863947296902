
import { Vue, Component } from 'vue-property-decorator';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

@Component({})
export default class ProfileEdit extends Vue {
  private verifyFlag = false;
  private firstName = '';
  private lastName = '';
  private emailAddress = '';
  private billingAddress = '';
  private userImage: Blob | null = null;
  private previewImage: string | null = null;
  private uploading = false;
  private progress = 0;
  private max = 0;
  private cropper: Cropper | null = null;
  private uploadedImage: string | null = null;

  async created(): Promise<void> {
    const { query } = this.$route;
    const { token } = query;
    if (token && typeof token === 'string') {
      await this.verify(token);
      await this.load();
    }
  }

  beforeDestroy() {
    this.cropperDestroy();
  }

  private cropperDestroy() {
    if (this.cropper) {
      this.cropper.clear();
      this.cropper.destroy();
    }
  }

  private async verify(token: string) {
    const { data, result } = await this.axios({
      url: `/user/token-confirm`,
      method: 'POST',
      data: {
        token
      }
    });
    this.verifyFlag = !!result;
    // if (!this.verifyFlag) await this.$router.replace({ path: '/my-page/profile' });
  }

  private async load(): Promise<void> {
    this.loading = true;
    const { data, result } = await this.axios({
      url: `/user/${this.accessToken}`,
      method: 'get',
    });
    if (result) {
      const { firstName, lastName, userEmail, billingAddress, profileImage } = data as { userGroup: string; firstName: string; lastName: string; userEmail: string; billingAddress: string; billingContactEmail: string; profileImage: string | null; };
      this.firstName = firstName;
      this.lastName = lastName;
      this.emailAddress = userEmail;
      this.billingAddress = billingAddress;
      this.uploadedImage = profileImage;
    }
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    this.loading = false;
  }

  private preview(image: File) {
    this.previewImage = null;
    this.uploadedImage = null;
    this.cropperDestroy();
    if (!image) return;
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent) => {
      const { target } = event;
      if (target) {
        const { result } = target as FileReader;
        const img = new Image();
        if (result && typeof result === 'string') {
          img.onload = async () => {
            const { width, height } = img;
            this.previewImage = img.src;
            await this.$nextTick();
            const ref = this.$refs.cropper as HTMLImageElement;
            this.cropper = new Cropper(ref, {
              initialAspectRatio: 1,
              aspectRatio: 1,
              cropend: this.cropEnd,
            });
          };
          img.src = result;
        }
        // if (result && typeof result === 'string') this.previewImage = result;
      }
    };
    reader.readAsDataURL(image);
  }

  private cropEnd() {
    if (this.cropper) {
      const data = this.cropper.getCroppedCanvas();
      console.log(data);
      if (data) {
        data.toBlob((blob) => {
          console.log(blob);
          this.userImage = blob;
        });
      }
    }
  }

  private async crop() {
    if (this.cropper) {
      const data = this.cropper.getCroppedCanvas();
      const url = data.toDataURL('image/jpeg');
      // this.previewImage = url;
      await this.$nextTick();
      const ref = this.$refs.cropper as HTMLImageElement;
      const crop = this.cropper.replace(url);
      this.cropper = crop;
      this.cropEnd();
    }
  }

  private cropReset() {
    if (this.cropper) {
      this.cropperDestroy();
      const ref = this.$refs.cropper as HTMLImageElement;
      this.cropper = new Cropper(ref, {
        initialAspectRatio: 1,
        aspectRatio: 1,
        cropend: this.cropEnd,
      });
      this.cropEnd();
    }
  }

  private rotateLeft() {
    if (this.cropper) {
      console.log(this.cropper);
      this.cropper.rotate(-90);
    }
  }

  private rotateRight() {
    if (this.cropper) {
      console.log(this.cropper);
      this.cropper.rotate(90);
    }
  }

  async updateProfile() {
    this.proc(true);
    try {
      const formData = new FormData();
      formData.append('firstName', this.firstName);
      formData.append('lastName', this.lastName);
      formData.append('userEmail', this.emailAddress);
      formData.append('billingAddress', this.billingAddress);
      if (this.userImage) {
        formData.append('file', this.userImage);
      }
      const { data, result, message } = await this.axios({
        url: `/user/profile`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 1e5,
        data: formData,
        onUploadProgress: evt => {
          const { loaded, total } = evt;
          this.progress = loaded;
          this.max = total;
          if (loaded === total) this.uploading = false;
        }
      });
      if (result && this.userImage) {
        const { user: { profileImage } } = data as { user: { profileImage: string } };
        this.userImage = null;
        // this.previewImage = null;
        if( profileImage) this.$store.commit('profileImage', profileImage);
      }

      if (!result) {
        this.validationMessages(message)
      }
    } catch (e) {
      console.log(e);
    }
    this.proc(false);
    this.cropperDestroy();
  }

  get accessToken() {
    return this.$store.getters.accessToken;
  }
}
